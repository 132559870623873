import React from "react";
import { AppBar, Container, Box, Toolbar, CssBaseline } from "@mui/material";
import { Link } from "react-router-dom";
import { Navbar } from "../components";

const Header = (props) => {
  return (
    <>
      <CssBaseline />
      <AppBar position="sticky" elevation={0}>
        <Container maxWidth="lg">
          <Toolbar
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Link to="/">
              <Box
                component="img"
                alt={props.imageAlt}
                src={props.imageSrc}
                paddingTop={0.8}
                sx={{
                  width: "auto",
                  maxWidth: 360,
                  height: {
                    xs: 42,
                    sm: 45,
                    md: 48,
                    lg: 51,
                  },
                }}
              />
            </Link>
            <Navbar />
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
};

export default Header;
