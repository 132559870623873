import React from "react";
import { Paper, Container, Typography, Box, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import { Projects } from "../components";
import "./Home.css";
import { useImages } from "../context/ImageContext";

const Home = () => {
  const allImages = useImages();
  const ftImages = allImages.filter((image) => image.title.startsWith("ft-"));

  return (
    <Paper elevation={0} square>
      <Container
        maxWidth="lg"
        disableGutters
        align="center"
        sx={{ paddingTop: 4, paddingBottom: 4 }}
      >
        <Box
          paddingBottom={4}
          paddingLeft={1.2}
          paddingRight={1.2}
          maxWidth={950}
        >
          <Typography variant="h6" sx={{ fontWeight: 400 }}>
            Hey, we're Red Sands Fabrication!
          </Typography>
          <Typography variant="body1" textAlign="center">
            Red Sands Fabrication is committed to serving Southern Utah with the
            highest quality products, services, and expertise. We hope you'll
            choose us for your fabrication needs and we look forward to working
            with you!
          </Typography>
        </Box>
        <Box
          paddingBottom={0.75}
          paddingLeft={1.2}
          paddingRight={1.2}
          maxWidth={950}
        >
          <Typography variant="h6" sx={{ fontWeight: 400 }}>
            Check out some of our featured builds
          </Typography>
        </Box>
        <Grid container justifyContent="center" alignItems="center">
          {ftImages.map((image) => (
            <Projects
              key={image.title}
              image={image.image}
              title={image.title}
            />
          ))}
        </Grid>
        <Box
          paddingTop={0.75}
          paddingBottom={4}
          paddingLeft={1.2}
          paddingRight={1.2}
          maxWidth={950}
        >
          <Typography variant="body1">
            {"You can find more of our projects "}
            <Link to="/portfolio" className="link-styles">
              here
            </Link>
            !
          </Typography>
        </Box>
        <Box paddingLeft={1.2} paddingRight={1.2} maxWidth={950}>
          <Typography variant="h6" sx={{ fontWeight: 400 }}>
            Ready to start a project?
          </Typography>
          <Typography variant="body1" textAlign="center">
            <Link to="/contact" className="link-styles">
              Contact us
            </Link>
            !
          </Typography>
        </Box>
      </Container>
    </Paper>
  );
};

export default Home;
