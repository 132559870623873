import React, { useState, useEffect, useCallback } from "react";
import {
  Stack,
  ButtonBase,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
import { Link } from "react-router-dom";
import { Spiral as Hamburger } from "hamburger-react";

const Navbar = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  useEffect(() => {
    const closeMenuOnScroll = () => {
      if (anchorEl) {
        handleMenuClose();
      }
    };

    window.addEventListener("scroll", closeMenuOnScroll);
    return () => {
      window.removeEventListener("scroll", closeMenuOnScroll);
    };
  }, [anchorEl, handleMenuClose]);

  return (
    <>
      {isMobile ? (
        <>
          <IconButton
            edge="end"
            aria-label="menu"
            onClick={handleMenuOpen}
            disableRipple={true}
          >
            <Hamburger
              size={25}
              toggled={Boolean(anchorEl)}
              toggle={setAnchorEl}
              color="#626567"
            />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            disableScrollLock={true}
            elevation={1}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }} // Aligns menu below
            transformOrigin={{ vertical: "top", horizontal: "right" }} // Aligns right side
          >
            <MenuItem onClick={handleMenuClose}>
              <Link
                to="/"
                style={{
                  textDecoration: "none",
                  color: "#626567",
                  width: "12rem",
                }}
              >
                Home
              </Link>
            </MenuItem>
            <MenuItem onClick={handleMenuClose}>
              <Link
                to="/portfolio"
                style={{
                  textDecoration: "none",
                  color: "#626567",
                  width: "12rem",
                }}
              >
                Portfolio
              </Link>
            </MenuItem>
            <MenuItem onClick={handleMenuClose}>
              <Link
                to="/contact"
                style={{
                  textDecoration: "none",
                  color: "#626567",
                  width: "12rem",
                }}
              >
                Contact Us
              </Link>
            </MenuItem>
          </Menu>
        </>
      ) : (
        <Stack spacing={4} direction="row">
          <ButtonBase disableRipple disableTouchRipple>
            <Link to="/" style={{ textDecoration: "none" }}>
              <Typography color="#626567">Home</Typography>
            </Link>
          </ButtonBase>
          <ButtonBase disableRipple disableTouchRipple>
            <Link to="/portfolio" style={{ textDecoration: "none" }}>
              <Typography color="#626567">Portfolio</Typography>
            </Link>
          </ButtonBase>
          <ButtonBase disableRipple disableTouchRipple>
            <Link to="/contact" style={{ textDecoration: "none" }}>
              <Typography color="#626567">Contact Us</Typography>
            </Link>
          </ButtonBase>
        </Stack>
      )}
    </>
  );
};

export default Navbar;
