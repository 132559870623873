import React from "react";
import { Card, CardMedia } from "@mui/material";

const PortfolioItem = (props) => {
  return (
    <Card elevation={0}>
      <CardMedia
        component="img"
        image={props.img}
        alt={props.imgAlt}
        sx={{
          width: "100%", // Ensure the image fills the width of the card
          // Adjust objectFit if needed, or remove if you want the natural height
          objectFit: "cover", // This will crop the image to fill the card
        }}
      />
    </Card>
  );
};

export default PortfolioItem;
