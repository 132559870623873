import React, { createContext, useState, useEffect, useContext } from "react";
import { BlobServiceClient, AnonymousCredential } from "@azure/storage-blob";

const ImagesContext = createContext();

const STORAGE_ACCOUNT = process.env.REACT_APP_AZURE_STORAGE_ACCOUNT_NAME;
const CONTAINER = process.env.REACT_APP_AZURE_STORAGE_ACCOUNT_CONTAINER_NAME;
const SAS_TOKEN = process.env.REACT_APP_AZURE_STORAGE_ACCOUNT_SAS_TOKEN;

const ImagesProvider = ({ children }) => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    const fetchImages = async () => {
      const blobServiceClient = new BlobServiceClient(
        `https://${STORAGE_ACCOUNT}.blob.core.windows.net?${SAS_TOKEN}`,
        new AnonymousCredential()
      );

      const containerClient = blobServiceClient.getContainerClient(CONTAINER);
      const blobs = [];
      for await (const blob of containerClient.listBlobsFlat()) {
        blobs.push({
          title: blob.name,
          image: containerClient.getBlockBlobClient(blob.name).url,
        });
      }

      // Preload images
      blobs.forEach((blob) => {
        const img = new Image();
        img.src = blob.image;
      });

      setImages(blobs);
    };

    fetchImages();
  }, []);

  return (
    <ImagesContext.Provider value={images}>{children}</ImagesContext.Provider>
  );
};

const useImages = () => {
  return useContext(ImagesContext);
};

export { ImagesProvider, useImages };
