import React from "react";
import { Grid, Card, CardMedia } from "@mui/material";

const Projects = (props) => {
  return (
    <Grid item padding={1.25}>
      <Card>
        <CardMedia
          component="img"
          image={props.image}
          alt={props.title}
          sx={{
            maxWidth: { xs: 355, sm: 370, lg: 380 },
          }}
        />
      </Card>
    </Grid>
  );
};

export default Projects;
