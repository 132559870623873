import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { PortfolioItem } from "../components";

const CustomMasonry = ({ allImages }) => {
  const getInitialColumns = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth < 600) {
      return 1;
    } else if (screenWidth < 900) {
      return 2;
    } else {
      return 3;
    }
  };

  const [columns, setColumns] = useState(getInitialColumns());

  useEffect(() => {
    const updateColumns = () => {
      setColumns(getInitialColumns());
    };

    window.addEventListener("resize", updateColumns);
    updateColumns(); // Set the initial column number on mount

    return () => window.removeEventListener("resize", updateColumns);
  }, []);

  const mapImagesToColumns = () => {
    const cols = Array.from({ length: columns }, () => []);
    allImages.forEach((image, i) => cols[i % columns].push(image));
    return cols;
  };

  const imageColumns = mapImagesToColumns();

  return (
    <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
      {imageColumns.map((col, idx) => (
        <Box key={idx} sx={{ width: `calc(100% / ${columns})` }}>
          {col.map((image, index) => (
            <Box key={index} sx={{ marginBottom: 2 }}>
              <PortfolioItem img={image.image} imgAlt={image.title} />
            </Box>
          ))}
        </Box>
      ))}
    </Box>
  );
};

export default CustomMasonry;
