import React from "react";
import { Paper, Container, Typography, Box, Link, Stack } from "@mui/material";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import CopyrightIcon from "@mui/icons-material/Copyright";

let phoneNumber = "435-669-0415";
let emailAddress = "antonio@redsandsfabrication.com";
let copyrightYear = "2023";
let copyrightCompany = "Red Sands Fabrication LLC";

const Footer = () => {
  return (
    <>
      <Paper
        sx={{ backgroundColor: "#DDD0C8", boxShadow: "0 50vh 0 50vh #DDD0C8" }}
        square
      >
        <Container>
          <Box sx={{ padding: 1 }} display="flex" justifyContent="center">
            <Stack>
              <Link underline="none" href={`tel:${phoneNumber}`}>
                <Typography
                  color="#626567"
                  variant="body2"
                  paddingTop={1}
                  paddingBottom={0.75}
                  textAlign="center"
                >
                  <CallOutlinedIcon sx={{ verticalAlign: "middle" }} />
                  {` ${phoneNumber}`}
                </Typography>
              </Link>
              <Link underline="none" href={`mailto:${emailAddress}`}>
                <Typography
                  color="#626567"
                  variant="body2"
                  paddingTop={0.75}
                  paddingBottom={1}
                  textAlign="center"
                >
                  <EmailOutlinedIcon sx={{ verticalAlign: "middle" }} />
                  {` ${emailAddress}`}
                </Typography>
              </Link>
            </Stack>
          </Box>
          <Typography
            align="center"
            sx={{ paddingBottom: 1, paddingTop: 2, verticalAlign: "bottom" }}
            variant="body2"
          >
            <CopyrightIcon
              fontSize="sm"
              sx={{ marginBottom: 0.4, verticalAlign: "middle" }}
            />
            {` ${copyrightYear} ${copyrightCompany}`}
          </Typography>
        </Container>
      </Paper>
    </>
  );
};

export default Footer;
