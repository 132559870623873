import { createTheme } from "@mui/material/styles";

const Theme = createTheme({
  palette: {
    primary: {
      main: "#DDD0C8",
    },
    text: {
      primary: "#626567",
    },
  },
});

export default Theme;
